._Xt-75 {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto; /* Enable scroll if needed */
  padding-top: 80px;
}
/* Modal content */
._3bARu {
  z-index: 999;
  position: absolute;
  top: 23%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  overflow: auto;
  background: #fff;
  padding: 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 4px;
  width: calc(100% - 120px); /* This just a default width */
  max-width: 1580px;
}
/* Close button */
._2I1sI {
  color: #aaaaaa;
  float: right;
  font-size: 25px;
  font-weight: bold;
}
._2I1sI:hover,
._2I1sI:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

._3qw0K {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 995;
  background: rgba(0, 0, 0, 0.85);
}
