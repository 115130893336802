.splash-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.splash-animation-container {
  height: 50vh;
}

.splash-title-container {
  font-size: 2.5rem;
  text-decoration: none;
}
.splash-title {
  font-family: "Agustina Regular", cursive;
  font-weight: bold;
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  padding: 0 20px;
}
 /*
@media (min-width: 768px) {
  
}
@media (max-width: 767px) and (min-width: 414px) {

}
@media (max-width: 413px) and (min-width: 321px) {

}

@media (max-width: 320px) {
  .splash-container {
    min-height: 50vh;
  }
  .splash-animation-container {
    height: 25vh;
  }
}
*/