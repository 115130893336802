.slide-pane {
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255);
  min-width: 100px;
  height: 100%;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s;
  will-change: transform;
}

.slide-pane__content {
  position: relative;
  overflow-y: auto;
  padding: 50px 32px;
  flex: 1 1 auto;
}

.slide-pane:focus {
  outline-style: none;
}

.slide-pane_from_bottom {
  height: 90vh;
  margin-top: 10vh;
  transform: translateY(100%);
}
.slide-pane_from_bottom.content-after-open {
  transform: translateY(0%);
}
.slide-pane_from_bottom.content-before-close {
  transform: translateY(100%);
}

.slide-pane__overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0);
}
.slide-pane__overlay.overlay-after-open {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.5s;
}
.slide-pane__overlay.overlay-before-close {
  background-color: rgba(0, 0, 0, 0);
}

.terminalContainer {
  //ackground-color: rgb(255, 255, 255);
  //padding: 0px 0px;
  //margin: 10px 10px 10px 10px;
  margin-top: 10px;
  //max-width: 100%;
}

//.terminalOutput {
  //background: white;
  //overflow-wrap: "break-all";
//}

@media (min-width: 768px) {
 
}
@media (max-width: 767px) and (min-width: 414px) {
  
}
@media (max-width: 413px) and (min-width: 321px) {
  
}

@media (max-width: 320px) {
  
}